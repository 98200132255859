/** @jsx jsx */
import { arrayOf, bool, string } from 'prop-types'
import { Fragment } from 'react'
import { Container, Heading, jsx, Text } from 'theme-ui'

import ButtonList from '~/components/ButtonList'
import IntroTransition from '~/components/IntroTransition'
import Section from '~/components/Section'
import renderLink from '~/content/renderLink'
import { LinkPropType, LinkType } from '~/types'

const DISPLAY_NAME = 'CtaSection'

const PROP_TYPES = {
  elementId: string,
  heading: string.isRequired,
  showGetAlpii: bool,
  links: arrayOf(LinkPropType),
}

interface PropTypes {
  elementId?: string,
  heading: string,
  showGetAlpii: boolean,
  links: LinkType[],
}

const Component = ({
  elementId,
  heading,
  showGetAlpii = true,
  links = [],
}: PropTypes) => (
  <Section id={elementId}>
    <Container variant="containerSmall">
      <IntroTransition>
        <div sx={{ textAlign: 'center' }}>
          <Heading
            sx={{
              variant: 'text.heading1',
              marginBottom: 2,
            }}
          >
            {heading}
            .
            {showGetAlpii && (
              <Fragment>
                {' '}
                <Text sx={{ whiteSpace: 'nowrap' }}>
                  Get
                  {' '}
                  <Text sx={{ color: 'brand' }}>Alpii</Text>
                  .
                </Text>
              </Fragment>
            )}
          </Heading>
          <ButtonList sx={{ justifyContent: [null, 'center'] }}>
            {links.map(({ id, ...link }) => (
              <li key={id}>
                {renderLink(link)}
              </li>
            ))}
          </ButtonList>
        </div>
      </IntroTransition>
    </Container>
  </Section>
)

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component
